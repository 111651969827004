<template>
  <v-container id="genetic-profile" fluid tag="section">
    <v-overlay class="results-overlay" :opacity="0.8" :value="overlay">
      <p>
        No cierre ni actualice la página. La operación puede tardar un par de
        minutos.
      </p>
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>

    <fondo-custom></fondo-custom>

    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-row>
          <v-col cols="12" sm="6" align-self="center">
            <page-top-title>
              <template v-slot:toptitle-icon>mdi-dna</template>
              <template v-slot:toptitle-text
                >{{ $t("perfil farmacogenetico") }}
              </template>
            </page-top-title>
          </v-col>
        </v-row>

        <!-- FILTROS -->
        <v-row>
          <v-container class="mt-10 mb-n10" fluid>
            <v-col>
              <h2 class="text-h3 primary--text">
                Busqueda por medicamentos y paneles
              </h2>
              <v-form ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="5" class="pa-0 pa-sm-2">
                      <drug-input-search />
                    </v-col>
                    <v-col cols="6" sm="5" class="pa-1 pa-sm-2">
                      <v-select
                        v-model="selectedPanel"
                        :items="sortedItems"
                        item-text="display"
                        item-value="value"
                        label="Panel"
                        rounded
                        outlined
                        background-color="white"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" sm="2" class="pa-1 pa-sm-2">
                      <v-text-field
                        v-model="search"
                        label="Gen"
                        background-color="white"
                        rounded
                        outlined
                        clearable
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-container>
        </v-row>

        <!-- RESULTADOS -->
        <v-row v-for="geneClass in Object.keys(geneClasses)" :key="geneClass">
          <v-container class="px-0 mx-sm-4" fluid>
            <v-col>
              <base-material-card class="my-2 px-0 px-sm-2">
                <template v-slot:after-heading>
                  <div class="text-h4 mt-8 grey--text">Grupo de genes</div>
                  <div class="card-title text-h2 font-weight-light mb-8">
                    {{ $t(geneClass) }}
                  </div>
                </template>
                <v-container
                  fluid
                  v-for="entry in getGenesByClass(geneClass)"
                  :key="entry[0]"
                  justify="space-between"
                >
                  <div
                    v-if="entry[0] != geneClass"
                    class="card-title text-h3 font-weight-light"
                  >
                    {{ $t(entry[0]) }}
                  </div>
                  <v-list class="gene-container" rounded>
                    <gene-card
                      v-for="gene in entry[1]"
                      :key="gene.gene"
                      :gene="gene"
                    />
                  </v-list>
                </v-container>

                <v-card-actions>
                  <v-divider></v-divider>
                  <v-btn
                    color="primary"
                    outlined
                    @click="
                      geneClasses[geneClass].show = !geneClasses[geneClass].show
                    "
                    rounded
                  >
                    Leyenda
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="geneClasses[geneClass].show">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            v-for="color in geneClasses[geneClass].colors"
                            :key="color"
                            cols="12"
                            md="6"
                            class="d-flex"
                          >
                            <v-icon
                              large
                              :color="color"
                              class="align-self-start mt-n2 mr-2"
                              >mdi-circle</v-icon
                            >
                            {{ $t(`legend_${geneClass}_${color}`) }}
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </base-material-card>
            </v-col>
          </v-container>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import genesPanels from "@/helpers/genes_paneles.js";
import { getUserGenotype } from "@/models/GenotypeModel.js";
import { getUserPGXGenes } from "@/models/PGXGenesModel.js";
import { mapGetters, mapActions } from "vuex";
import GeneCard from "@/components/mylogy/GeneCard.vue";
import FondoCustom from "@/components/mylogy/FondoCustom.vue";
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";
import DrugInputSearch from "@/components/mylogy/pgx_results/DrugInputSearch.vue";

var slugify = require("slugify");

export default {
  components: { GeneCard, FondoCustom, PageTopTitle, DrugInputSearch },
  props: ["userId"],
  data() {
    return {
      overlay: false,
      pusher: null,
      channel: null,
      show: false,

      colors: ["grey", "#b0e0e6", "green", "yellow", "orange", "red"],
      geneClasses: {
        phase: {
          show: false,
          colors: ["grey", "green", "yellow", "orange", "red"]
        },
        transporter: {
          show: false,
          colors: ["grey", "green", "yellow", "red"]
        },
        receptor: {
          show: false,
          colors: ["grey", "green", "yellow", "red"]
        },
        pathogenic: {
          show: false,
          colors: ["grey", "green", "yellow", "red"]
        }
      },
      items: [
        {
          display: "Anticonvulsivos",
          value: "ANTICONVULSIVOS"
        },
        {
          display: "Detoxificación",
          value: "DETOX"
        },
        {
          display: "Antidepresivos",
          value: "ANTIDEPRESIVOS"
        },
        {
          display: "PERFIL FARMACOGENÉTICO",
          value: "CARD60"
        },
        {
          display: "Antipsicóticos",
          value: "ANTIPSICOTICOS"
        },
        {
          display: "Antiparkinsonianos",
          value: "ANTIPARKINSONIANOS"
        },
        {
          display: "ANTI-TDAH",
          value: "ANTI-TDAH"
        },
        {
          display: "Anestésicos",
          value: "ANESTESICOS"
        },
        {
          display: "Analgésicos",
          value: "ANALGESICOS"
        },
        {
          display: "Ansiolíticos",
          value: "ANSIOLITICOS"
        },
        {
          display: "Agentes Antidemenciales",
          value: "ANTIDEMENCIA"
        }
      ],
      search: "",
      selectedPanel: "CARD60",
      headers: [
        {
          text: this.$t("GEN"),
          align: "start",
          value: "gene"
        },
        { text: this.$t("SNP"), value: "snp" },
        { text: this.$t("Genotipo"), value: "genotype" },
        { text: this.$t("Interpretación"), value: "result" }
      ]
    };
  },
  computed: {
    ...mapGetters("pgx", ["getPgx", "getDrugResult"]),
    ...mapGetters("app", ["apiSufix"]),
    ...mapGetters("auth", ["currentUser"]),
    sortedItems() {
      return [...this.items].sort((a, b) =>
        a.display > b.display ? 1 : b.display > a.display ? -1 : 0
      );
    },
    genes() {
      let self = this;
      return this.getPgx
        .map(e => {
          e.result = self.colors[e.code];
          return e;
        })
        .filter(g => {
          return (
            genesPanels[slugify(g.gene, { lower: true })] &&
            genesPanels[slugify(g.gene, { lower: true })].includes(
              self.selectedPanel
            ) &&
            slugify(g.gene, { lower: true }).includes(
              slugify(this.search, { lower: true })
            )
          );
        });
    }
  },
  created() {
    getUserGenotype(this.userId).then(res => this.setGenotype(res));
    getUserPGXGenes(this.userId).then(res => this.setPGXGenes(res));
  },
  mounted() {},
  methods: {
    ...mapActions("pgx", ["setGenotype", "setPGXGenes"]),
    getGenesByClass(geneClass) {
      const groupBy = (items, key) =>
        items.reduce(
          (result, item) => ({
            ...result,
            [item[key]]: [...(result[item[key]] || []), item]
          }),
          {}
        );
      const genes = this.genes.filter(g => g["class"].includes(geneClass));

      return Object.entries(groupBy(genes, "class")).reverse();
    }
  }
};
</script>

<style lang="scss" scoped>
.gene-container {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 10px;
  row-gap: 0px;
  justify-items: center;
}
</style>

<style lang="scss">
fieldset {
  border-color: #4d2cb9 !important;
}
.css-tprr8g {
  height: 56px !important;
  border-radius: 50px !important;
  background: white !important;
}
.css-1ee1q46,
.css-l5274x {
  top: 15px !important;
}
.suggestions {
  position: absolute;
  z-index: 3;
  box-shadow: 0 0 20px #4d2cb920 !important;
  //border: solid 1px#411285 !important;

  ul {
    list-style: none;
    padding-left: 0;
    max-height: 400px;
    overflow-y: overlay;
  }
  li {
    border-bottom: solid 1px #f0f0f0;
    transition: 0.2s;
    &:hover {
      background: #f0f0f0;
    }
  }
  .v-icon {
    height: 1rem;
  }
}
</style>
